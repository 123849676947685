var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "NewTheme", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { staticClass: "justify-center text-center", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "v-card--material", attrs: { flat: "" } },
                [
                  _c(
                    "card-heading",
                    {
                      attrs: { title: "Edit Group Theme", color: "secondary" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function ({ title }) {
                            return [
                              _c(
                                "span",
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(title)),
                                  ]),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        left: "",
                                        color: "error darken-2",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on: tooltip }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        fab: "",
                                                        elevation: "0",
                                                        color: "red",
                                                        "x-small": "",
                                                      },
                                                    },
                                                    tooltip
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-exclamation"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _c("h4", [
                                          _vm._v("Groups using this theme:"),
                                        ]),
                                        _c(
                                          "ul",
                                          _vm._l(
                                            _vm.groupsListWithTheme,
                                            function (groups) {
                                              return _c(
                                                "li",
                                                {
                                                  key: groups.id,
                                                  staticClass: "text-center",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(groups.name) +
                                                      " (ID: " +
                                                      _vm._s(groups.id) +
                                                      ") "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { fab: "", small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.importDialog = true
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-arrow-up-bold-circle"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: { fab: "", small: "", color: "primary" },
                              on: { click: _vm.exportJson },
                            },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-arrow-down-bold-circle"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-2" },
                    [
                      _vm.getFilteredThemesList.data &&
                      _vm.$route.params.jackpot_group_id
                        ? _c(
                            "v-card",
                            {
                              staticClass: "mt-0 mb-6",
                              attrs: { color: "background" },
                            },
                            [
                              _c("div", { staticClass: "cardHeader" }, [
                                _vm._v("CONFIG"),
                              ]),
                              _c(
                                "v-row",
                                { staticClass: "ml-6" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: 12, sm: 6, md: 3 } },
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "my-2 mr-7 mr-sm-4 mr-lg-2",
                                        attrs: {
                                          outlined: "",
                                          items:
                                            _vm.getFilteredThemesList.data.data,
                                          "hide-details": "",
                                          "item-text": "name",
                                          label: "Themes List",
                                          "return-object": "",
                                        },
                                        model: {
                                          value: _vm.selectedTheme,
                                          callback: function ($$v) {
                                            _vm.selectedTheme = $$v
                                          },
                                          expression: "selectedTheme",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.groupsListWithTheme.length > 1
                                    ? _c("v-col", {
                                        class: _vm.$route.params
                                          .jackpot_group_id
                                          ? "col-3 red--text text-center"
                                          : "col-9 red--text text-center",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "px-3 my-3 mx-0 mt-0 pl-5 pb-2 cardExpand",
                          attrs: {
                            flat: _vm.$vuetify.theme.dark,
                            height: _vm.cardHeight,
                            color: "background",
                          },
                        },
                        [
                          _c("div", { staticClass: "cardHeader" }, [
                            _vm._v("Assets"),
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pb-0" },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "formiq",
                                  on: { input: _vm.thisChanged },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: 12, sm: 12, md: 4 },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: "Loader Logo",
                                              rules: [_vm.rules.required],
                                            },
                                            model: {
                                              value: _vm.themeLoaderLogo,
                                              callback: function ($$v) {
                                                _vm.themeLoaderLogo = $$v
                                              },
                                              expression: "themeLoaderLogo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: 12, sm: 6, md: 4 },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: "Theme Logo",
                                              rules: [_vm.rules.required],
                                            },
                                            model: {
                                              value: _vm.themeLogo,
                                              callback: function ($$v) {
                                                _vm.themeLogo = $$v
                                              },
                                              expression: "themeLogo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: 12, sm: 6, md: 4 },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: "Theme Logo Small",
                                              rules: [_vm.rules.required],
                                            },
                                            model: {
                                              value: _vm.themeLogoSmall,
                                              callback: function ($$v) {
                                                _vm.themeLogoSmall = $$v
                                              },
                                              expression: "themeLogoSmall",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: 12, sm: 6, md: 6 },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: "Win Anim logo",
                                              rules: [_vm.rules.required],
                                            },
                                            model: {
                                              value: _vm.themeWinLogo,
                                              callback: function ($$v) {
                                                _vm.themeWinLogo = $$v
                                              },
                                              expression: "themeWinLogo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: 12, sm: 6, md: 6 },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: "Bonus Name",
                                            },
                                            model: {
                                              value: _vm.bonusName,
                                              callback: function ($$v) {
                                                _vm.bonusName = $$v
                                              },
                                              expression: "bonusName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass: "px-3 my-3 mx-0",
                          attrs: {
                            flat: _vm.$vuetify.theme.dark,
                            color: "background",
                          },
                        },
                        [
                          _c("div", { staticClass: "cardHeader" }, [
                            _vm._v("Colors"),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "d-flex flex-wrap justify-center text-center",
                                },
                                [
                                  !_vm.isMobile
                                    ? _c(
                                        "v-item-group",
                                        {
                                          staticClass: "col-3 pa-2",
                                          attrs: { mandatory: "" },
                                          model: {
                                            value: _vm.selectedScheme,
                                            callback: function ($$v) {
                                              _vm.selectedScheme = $$v
                                            },
                                            expression: "selectedScheme",
                                          },
                                        },
                                        _vm._l(_vm.levelList, function (level) {
                                          return _c("v-item", {
                                            key: level.id,
                                            staticClass: "col-12",
                                            attrs: { value: level.name },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    active,
                                                    toggle,
                                                  }) {
                                                    return [
                                                      _c("v-hover", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                hover,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: [
                                                                        active ||
                                                                        hover
                                                                          ? "text--primary kajot_light1 rounded"
                                                                          : "text--secondary",
                                                                      ],
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          toggle,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm.groupThemeCopy &&
                                                                      _vm
                                                                        .groupThemeCopy
                                                                        .setting &&
                                                                      _vm
                                                                        .groupThemeCopy
                                                                        .setting[
                                                                        level
                                                                          .name
                                                                      ] &&
                                                                      _vm
                                                                        .groupThemeCopy
                                                                        .setting[
                                                                        level
                                                                          .name
                                                                      ]
                                                                        .borderColors
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "LevelPreview",
                                                                                {
                                                                                  key: _vm.fuckingCounter2,
                                                                                  class:
                                                                                    _vm.wrongAnim.has(
                                                                                      level.name.toLowerCase()
                                                                                    )
                                                                                      ? "shake"
                                                                                      : "",
                                                                                  attrs:
                                                                                    {
                                                                                      previewWidth:
                                                                                        "20vw",
                                                                                      previewHeight:
                                                                                        "7vh",
                                                                                      previewBorderWidth:
                                                                                        "3px",
                                                                                      iconUrl:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .iconUrl,
                                                                                      iconTint:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .iconTint,
                                                                                      textColor:
                                                                                        _vm.wRoNg.has(
                                                                                          level.name.toLowerCase()
                                                                                        )
                                                                                          ? "error"
                                                                                          : "",
                                                                                      backgroundColors:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .backgroundColors,
                                                                                      backgroundColorStops:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .backgroundColorStops,
                                                                                      textColors:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .textColors,
                                                                                      textColorStops:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .textColorStops,
                                                                                      borderColors:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .borderColors,
                                                                                      borderColorStops:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .borderColorStops,
                                                                                      visible_name:
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .visible_name,
                                                                                      image_name:
                                                                                        level.name,
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "btnPrepend",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _vm._usrFlagsSome(
                                                                                                {
                                                                                                  key: "bonus.group_themes.detail",
                                                                                                  val: _vm
                                                                                                    .permissions
                                                                                                    .EDIT,
                                                                                                }
                                                                                              )
                                                                                                ? _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            "kajot-text",
                                                                                                          fab: "",
                                                                                                          "x-small":
                                                                                                            "",
                                                                                                          text: "",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            return _vm.handleRestore(
                                                                                                              level.name
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              size: "18px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-restore"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                        {
                                                                                          key: "btnAppend",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _vm._usrFlagsSome(
                                                                                                {
                                                                                                  key: "bonus.group_themes.detail",
                                                                                                  val: _vm
                                                                                                    .permissions
                                                                                                    .EDIT,
                                                                                                }
                                                                                              )
                                                                                                ? _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            "error",
                                                                                                          fab: "",
                                                                                                          "x-small":
                                                                                                            "",
                                                                                                          text: "",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            return _vm.handleUnset(
                                                                                                              level.name
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              size: "22px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-close"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-block",
                                                                                  class:
                                                                                    _vm.wRoNg.has(
                                                                                      level.name.toLowerCase()
                                                                                    )
                                                                                      ? "error--text"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .groupThemeCopy
                                                                                          .setting[
                                                                                          level
                                                                                            .name
                                                                                        ]
                                                                                          .iconUrl
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm.wRoNg.has(
                                                                                      level.name.toLowerCase()
                                                                                    )
                                                                                      ? "error--text"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Level: " +
                                                                                      _vm._s(
                                                                                        level.name
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-center",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " level scheme not defined "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isMobile
                                    ? _c("v-select", {
                                        staticClass: "ml-2 px-3 pt-0 mt-0",
                                        attrs: {
                                          "append-icon": "",
                                          outlined: "",
                                          mandatory: "",
                                          items: _vm.levelList,
                                          "item-value": "name",
                                          "item-text": "name",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function ({ item }) {
                                                return [
                                                  _c("LevelPreview", {
                                                    key: `${_vm.fuckingCounter2}-${_vm.fuckingCounter5}`,
                                                    class: _vm.wrongAnim.has(
                                                      item.name.toLowerCase()
                                                    )
                                                      ? "shake"
                                                      : "",
                                                    attrs: {
                                                      previewWidth: "100vw",
                                                      previewHeight: "7vh",
                                                      previewBorderWidth: "3px",
                                                      iconUrl:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.iconUrl,
                                                      iconTint:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.iconTint,
                                                      textColor: _vm.wRoNg.has(
                                                        item.name.toLowerCase()
                                                      )
                                                        ? "error"
                                                        : "",
                                                      backgroundColors:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.backgroundColors,
                                                      backgroundColorStops:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.backgroundColorStops,
                                                      textColors:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.textColors,
                                                      textColorStops:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.textColorStops,
                                                      borderColors:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.borderColors,
                                                      borderColorStops:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.borderColorStops,
                                                      visible_name:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          ?.visible_name,
                                                      image_name: item.name,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item",
                                              fn: function ({ item }) {
                                                return [
                                                  _c("LevelPreview", {
                                                    class: _vm.wrongAnim.has(
                                                      item.name.toLowerCase()
                                                    )
                                                      ? "shake"
                                                      : "",
                                                    attrs: {
                                                      previewWidth: "100vw",
                                                      previewHeight: "7vh",
                                                      previewBorderWidth: "3px",
                                                      iconUrl:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .iconUrl,
                                                      iconTint:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .iconTint,
                                                      textColor: _vm.wRoNg.has(
                                                        item.name.toLowerCase()
                                                      )
                                                        ? "error"
                                                        : "",
                                                      backgroundColors:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .backgroundColors,
                                                      backgroundColorStops:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .backgroundColorStops,
                                                      textColors:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .textColors,
                                                      textColorStops:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .textColorStops,
                                                      borderColors:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .borderColors,
                                                      borderColorStops:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .borderColorStops,
                                                      visible_name:
                                                        _vm.groupThemeCopy
                                                          .setting[item.name]
                                                          .visible_name,
                                                      image_name: item.name,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2374004619
                                        ),
                                        model: {
                                          value: _vm.selectedScheme,
                                          callback: function ($$v) {
                                            _vm.selectedScheme = $$v
                                          },
                                          expression: "selectedScheme",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._usrFlagsSome({
                                    key: "bonus.group_themes.detail",
                                    val:
                                      _vm.permissions.EDIT |
                                      _vm.permissions.CREATE,
                                  })
                                    ? _c("edit-scheme", {
                                        key: `fml-${_vm.testCounter}`,
                                        staticClass:
                                          "col-12 col-md-9 px-0 pt-0",
                                        attrs: {
                                          schemeToEdit: _vm.getScheme,
                                          schemeName: _vm.selectedScheme,
                                        },
                                        on: {
                                          borderChange: _vm.updateBorder,
                                          backgroundChange:
                                            _vm.updateBackground,
                                          textChange: _vm.updateText,
                                          iconTintChange: _vm.updateIconTint,
                                          animationTintChange:
                                            _vm.updateAnimationTint,
                                          textStrokeChange:
                                            _vm.updateTextStroke,
                                          visibleNameChange:
                                            _vm.updateVisibleName,
                                          iconUrlChange: _vm.updateIconUrl,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "stickyButtons" },
                    [
                      _c(
                        "v-btn",
                        { attrs: { text: "", to: _vm.routerObject } },
                        [_vm._v(" Cancel ")]
                      ),
                      _c("v-spacer"),
                      _vm._usrFlagsSome({
                        key: "bonus.group_themes.detail",
                        val: _vm.permissions.EDIT,
                      })
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" Submit ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        ref: "dialog",
        attrs: {
          validator: _vm.jsonValidator,
          errorTimeout: 1500,
          title: "Theme Import",
          widths: [500, 800],
          steps: 2,
          submitButtonText: "Import",
          format: ["application/json"],
          dropFileType: "JSON",
          parser: "jsonParser",
          "no-hint": "",
          importDialog: _vm.importDialog,
        },
        on: {
          step: _vm.stepArtifact,
          submit: _vm.onImport,
          "update:importDialog": function ($event) {
            _vm.importDialog = $event
          },
          "update:import-dialog": function ($event) {
            _vm.importDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "file-detail",
            fn: function ({ data }) {
              return [
                _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        Object.keys(data.setting ?? {}).filter((el) => {
                          const setting = data.setting[el]
                          return _vm.isTrueSetting(setting)
                        }).length
                      ) +
                      " levels "
                  ),
                ]),
              ]
            },
          },
          {
            key: "actions1-next",
            fn: function ({ listUpload, step, next }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { disabled: step == 1 && !listUpload, text: "" },
                    on: { click: next },
                  },
                  [_vm._v(" Next ")]
                ),
              ]
            },
          },
          {
            key: "actions1-submit",
            fn: function () {
              return [_vm._v(" ")]
            },
            proxy: true,
          },
          {
            key: "actions2",
            fn: function ({ prev, submit }) {
              return [
                _c("v-btn", { attrs: { text: "" }, on: { click: prev } }, [
                  _vm._v(" Previous "),
                ]),
                _c("v-btn", { attrs: { text: "" }, on: { click: submit } }, [
                  _vm._v(" Submit "),
                ]),
              ]
            },
          },
          {
            key: "step-2-header",
            fn: function () {
              return [_vm._v("Level match")]
            },
            proxy: true,
          },
          {
            key: "step-2-content",
            fn: function ({ parserResult }) {
              return [
                _c(
                  "v-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !parserResult,
                        expression: "!parserResult",
                      },
                    ],
                  },
                  [_vm._v("mdi-close")]
                ),
                _c(
                  "v-card",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: parserResult,
                        expression: "parserResult",
                      },
                    ],
                    staticClass: "elevation-0",
                    attrs: { flat: "" },
                  },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "pa-0 mb-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mb-3",
                            staticStyle: { height: "11vh" },
                            attrs: { id: "lprev" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "17px",
                                  left: "28px",
                                  right: "31px",
                                },
                              },
                              [
                                _c(
                                  "LevelPreview",
                                  _vm._b(
                                    {
                                      ref: "emptyPreview",
                                      attrs: {
                                        hideName: "",
                                        previewWidth: "100%",
                                        previewHeight: "7vh",
                                        previewBorderWidth: "3px",
                                      },
                                    },
                                    "LevelPreview",
                                    _vm.hoveredLevel,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._l(
                          _vm.getFilteredLevelList?.data?.data,
                          function (level, index) {
                            return _c(
                              "v-row",
                              { key: index, staticClass: "mx-2 align-center" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 my-1",
                                    attrs: { cols: "8" },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        items: [
                                          "None",
                                          ...Object.keys(
                                            parserResult?.data?.setting ?? {}
                                          ).filter((el) => {
                                            const setting =
                                              parserResult?.data?.setting[el]
                                            return _vm.isTrueSetting(setting)
                                          }),
                                        ],
                                        mandatory: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function ({ item, on, attrs }) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { dense: "" },
                                                        on: {
                                                          mouseenter: (e) =>
                                                            _vm.handleLevelHover(
                                                              e,
                                                              parserResult?.data
                                                                ?.setting[item]
                                                            ),
                                                          mouseleave: (e) =>
                                                            _vm.handleLevelHover(
                                                              e,
                                                              parserResult?.data
                                                                ?.setting[item]
                                                            ),
                                                        },
                                                      },
                                                      "v-list-item",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("Capitalize")(
                                                            item !== "None"
                                                              ? item
                                                              : ""
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function ({ item, on, attrs }) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        on: {
                                                          mouseenter: (e) =>
                                                            _vm.handleLevelHover(
                                                              e,
                                                              parserResult?.data
                                                                ?.setting[item]
                                                            ),
                                                          mouseleave: (e) =>
                                                            _vm.handleLevelHover(
                                                              e,
                                                              parserResult?.data
                                                                ?.setting[item]
                                                            ),
                                                        },
                                                      },
                                                      "v-list-item",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("Capitalize")(
                                                            item !== "None"
                                                              ? item
                                                              : ""
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.importMap[level.name],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.importMap,
                                            level.name,
                                            $$v
                                          )
                                        },
                                        expression: "importMap[level.name]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-0", attrs: { cols: "1" } },
                                  [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-0 text-right" },
                                  [
                                    _c("b", { staticClass: "text-button" }, [
                                      _vm._v(_vm._s(level.name)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }