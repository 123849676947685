<template>
	<v-container
		id="NewTheme"
		fluid
		tag="section">
		<v-row
			dense
			class="justify-center text-center">
			<v-col cols="12">
				<v-card
					flat
					class="v-card--material">
					<card-heading
						title="Edit Group Theme"
						color="secondary">
						<template #title="{ title }">
							<span>
								<span class="mr-2">{{ title }}</span>
								<v-tooltip
									left
									color="error darken-2">
									<template v-slot:activator="{ on: tooltip }">
										<v-btn
											fab
											elevation="0"
											color="red"
											x-small
											v-on="tooltip">
											<v-icon>mdi-exclamation</v-icon>
										</v-btn>
									</template>
									<span>
										<h4>Groups using this theme:</h4>
										<ul>
											<li
												v-for="groups of groupsListWithTheme"
												v-bind:key="groups.id"
												class="text-center">
												{{ groups.name }} (ID: {{ groups.id }})
											</li>
										</ul>
									</span>
								</v-tooltip>
							</span>
						</template>
						<span>
							<v-btn
								@click="importDialog = true"
								fab
								small
								color="primary">
								<v-icon>mdi-arrow-up-bold-circle</v-icon>
							</v-btn>
							<v-btn
								class="mx-2"
								@click="exportJson"
								fab
								small
								color="primary">
								<v-icon>mdi-arrow-down-bold-circle</v-icon>
							</v-btn>
						</span>
					</card-heading>
					<v-card-text class="px-2">
						<v-card
							v-if="
								getFilteredThemesList.data && $route.params.jackpot_group_id
							"
							color="background"
							class="mt-0 mb-6">
							<div class="cardHeader">CONFIG</div>
							<v-row class="ml-6">
								<v-col
									:cols="12"
									:sm="6"
									:md="3">
									<v-select
										outlined
										class="my-2 mr-7 mr-sm-4 mr-lg-2"
										:items="getFilteredThemesList.data.data"
										v-model="selectedTheme"
										hide-details
										item-text="name"
										label="Themes List"
										return-object></v-select>
								</v-col>
								<v-col
									v-if="groupsListWithTheme.length > 1"
									:class="
										$route.params.jackpot_group_id
											? 'col-3 red--text text-center'
											: 'col-9 red--text text-center'
									"></v-col>
							</v-row>
						</v-card>
						<v-card
							:flat="$vuetify.theme.dark"
							:height="cardHeight"
							color="background"
							class="px-3 my-3 mx-0 mt-0 pl-5 pb-2 cardExpand">
							<div class="cardHeader">Assets</div>
							<v-card-text class="pb-0">
								<v-form
									@input="thisChanged"
									ref="formiq">
									<v-row>
										<v-col
											:cols="12"
											:sm="12"
											:md="4"
											class="pb-0">
											<v-text-field
												outlined
												hide-details="auto"
												v-model="themeLoaderLogo"
												label="Loader Logo"
												:rules="[rules.required]"></v-text-field>
										</v-col>
										<v-col
											:cols="12"
											:sm="6"
											:md="4"
											class="pb-0">
											<v-text-field
												outlined
												hide-details="auto"
												v-model="themeLogo"
												label="Theme Logo"
												:rules="[rules.required]"></v-text-field>
										</v-col>
										<v-col
											:cols="12"
											:sm="6"
											:md="4"
											class="pb-0">
											<v-text-field
												outlined
												hide-details="auto"
												v-model="themeLogoSmall"
												label="Theme Logo Small"
												:rules="[rules.required]"></v-text-field>
										</v-col>
										<v-col
											:cols="12"
											:sm="6"
											:md="6"
											class="pb-0">
											<v-text-field
												outlined
												hide-details="auto"
												v-model="themeWinLogo"
												label="Win Anim logo"
												:rules="[rules.required]"></v-text-field>
										</v-col>
										<v-col
											:cols="12"
											:sm="6"
											:md="6"
											class="pb-0">
											<v-text-field
												outlined
												hide-details="auto"
												v-model="bonusName"
												label="Bonus Name"></v-text-field>
										</v-col>
									</v-row>
								</v-form>
							</v-card-text>
						</v-card>
						<v-card
							:flat="$vuetify.theme.dark"
							color="background"
							class="px-3 my-3 mx-0">
							<div class="cardHeader">Colors</div>
							<v-card-text>
								<v-row class="d-flex flex-wrap justify-center text-center">
									<v-item-group
										v-if="!isMobile"
										v-model="selectedScheme"
										class="col-3 pa-2"
										mandatory>
										<v-item
											class="col-12"
											v-for="level of levelList"
											:key="level.id"
											:value="level.name">
											<template v-slot:default="{ active, toggle }">
												<v-hover v-slot="{ hover }">
													<div
														:class="[
															active || hover
																? 'text--primary kajot_light1 rounded'
																: 'text--secondary',
														]"
														style="cursor: pointer"
														@click="toggle">
														<span
															v-if="
																groupThemeCopy &&
																groupThemeCopy.setting &&
																groupThemeCopy.setting[level.name] &&
																groupThemeCopy.setting[level.name].borderColors
															">
															<LevelPreview
																:key="fuckingCounter2"
																previewWidth="20vw"
																previewHeight="7vh"
																previewBorderWidth="3px"
																:class="
																	wrongAnim.has(level.name.toLowerCase())
																		? 'shake'
																		: ''
																"
																:iconUrl="
																	groupThemeCopy.setting[level.name].iconUrl
																"
																:iconTint="
																	groupThemeCopy.setting[level.name].iconTint
																"
																:textColor="
																	wRoNg.has(level.name.toLowerCase())
																		? 'error'
																		: ''
																"
																:backgroundColors="
																	groupThemeCopy.setting[level.name]
																		.backgroundColors
																"
																:backgroundColorStops="
																	groupThemeCopy.setting[level.name]
																		.backgroundColorStops
																"
																:textColors="
																	groupThemeCopy.setting[level.name].textColors
																"
																:textColorStops="
																	groupThemeCopy.setting[level.name]
																		.textColorStops
																"
																:borderColors="
																	groupThemeCopy.setting[level.name]
																		.borderColors
																"
																:borderColorStops="
																	groupThemeCopy.setting[level.name]
																		.borderColorStops
																"
																:visible_name="
																	groupThemeCopy.setting[level.name]
																		.visible_name
																"
																:image_name="level.name">
																<template v-slot:btnPrepend>
																	<v-btn
																		color="kajot-text"
																		v-if="
																			_usrFlagsSome({
																				key: 'bonus.group_themes.detail',
																				val: permissions.EDIT,
																			})
																		"
																		@click.stop="handleRestore(level.name)"
																		fab
																		x-small
																		text>
																		<v-icon size="18px">mdi-restore</v-icon>
																	</v-btn>
																</template>
																<template v-slot:btnAppend>
																	<v-btn
																		v-if="
																			_usrFlagsSome({
																				key: 'bonus.group_themes.detail',
																				val: permissions.EDIT,
																			})
																		"
																		color="error"
																		@click.stop="handleUnset(level.name)"
																		fab
																		x-small
																		text>
																		<v-icon size="22px">mdi-close</v-icon>
																	</v-btn>
																</template>
															</LevelPreview>
															<span
																class="d-block"
																:class="
																	wRoNg.has(level.name.toLowerCase())
																		? 'error--text'
																		: ''
																">
																{{ groupThemeCopy.setting[level.name].iconUrl }}
															</span>
															<span
																:class="
																	wRoNg.has(level.name.toLowerCase())
																		? 'error--text'
																		: ''
																">
																Level:
																{{ level.name }}
															</span>
														</span>

														<div
															v-else
															class="text-center">
															level scheme not defined
														</div>
													</div>
												</v-hover>
											</template>
										</v-item>
									</v-item-group>
									<v-select
										append-icon=""
										outlined
										class="ml-2 px-3 pt-0 mt-0"
										mandatory
										:items="levelList"
										v-if="isMobile"
										item-value="name"
										item-text="name"
										v-model="selectedScheme">
										<template #selection="{ item }">
											<LevelPreview
												:key="`${fuckingCounter2}-${fuckingCounter5}`"
												previewWidth="100vw"
												previewHeight="7vh"
												previewBorderWidth="3px"
												:class="
													wrongAnim.has(item.name.toLowerCase()) ? 'shake' : ''
												"
												:iconUrl="groupThemeCopy.setting[item.name]?.iconUrl"
												:iconTint="groupThemeCopy.setting[item.name]?.iconTint"
												:textColor="
													wRoNg.has(item.name.toLowerCase()) ? 'error' : ''
												"
												:backgroundColors="
													groupThemeCopy.setting[item.name]?.backgroundColors
												"
												:backgroundColorStops="
													groupThemeCopy.setting[item.name]
														?.backgroundColorStops
												"
												:textColors="
													groupThemeCopy.setting[item.name]?.textColors
												"
												:textColorStops="
													groupThemeCopy.setting[item.name]?.textColorStops
												"
												:borderColors="
													groupThemeCopy.setting[item.name]?.borderColors
												"
												:borderColorStops="
													groupThemeCopy.setting[item.name]?.borderColorStops
												"
												:visible_name="
													groupThemeCopy.setting[item.name]?.visible_name
												"
												:image_name="item.name"></LevelPreview>
										</template>
										<template #item="{ item }">
											<LevelPreview
												previewWidth="100vw"
												previewHeight="7vh"
												previewBorderWidth="3px"
												:class="
													wrongAnim.has(item.name.toLowerCase()) ? 'shake' : ''
												"
												:iconUrl="groupThemeCopy.setting[item.name].iconUrl"
												:iconTint="groupThemeCopy.setting[item.name].iconTint"
												:textColor="
													wRoNg.has(item.name.toLowerCase()) ? 'error' : ''
												"
												:backgroundColors="
													groupThemeCopy.setting[item.name].backgroundColors
												"
												:backgroundColorStops="
													groupThemeCopy.setting[item.name].backgroundColorStops
												"
												:textColors="
													groupThemeCopy.setting[item.name].textColors
												"
												:textColorStops="
													groupThemeCopy.setting[item.name].textColorStops
												"
												:borderColors="
													groupThemeCopy.setting[item.name].borderColors
												"
												:borderColorStops="
													groupThemeCopy.setting[item.name].borderColorStops
												"
												:visible_name="
													groupThemeCopy.setting[item.name].visible_name
												"
												:image_name="item.name"></LevelPreview>
										</template>
									</v-select>
									<edit-scheme
										v-if="
											_usrFlagsSome({
												key: 'bonus.group_themes.detail',
												val: permissions.EDIT | permissions.CREATE,
											})
										"
										:key="`fml-${testCounter}`"
										class="col-12 col-md-9 px-0 pt-0"
										:schemeToEdit="getScheme"
										:schemeName="selectedScheme"
										v-on:borderChange="updateBorder"
										v-on:backgroundChange="updateBackground"
										v-on:textChange="updateText"
										v-on:iconTintChange="updateIconTint"
										v-on:animationTintChange="updateAnimationTint"
										v-on:textStrokeChange="updateTextStroke"
										v-on:visibleNameChange="updateVisibleName"
										v-on:iconUrlChange="updateIconUrl"></edit-scheme>
								</v-row>
							</v-card-text>
						</v-card>
					</v-card-text>
					<v-card-actions class="stickyButtons">
						<v-btn
							text
							:to="routerObject">
							Cancel
						</v-btn>
						<v-spacer />
						<v-btn
							v-if="
								_usrFlagsSome({
									key: 'bonus.group_themes.detail',
									val: permissions.EDIT,
								})
							"
							color="primary"
							text
							@click="submit">
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<ImportDialog
			ref="dialog"
			:validator="jsonValidator"
			:errorTimeout="1500"
			title="Theme Import"
			@step="stepArtifact"
			:widths="[500, 800]"
			:steps="2"
			submitButtonText="Import"
			:format="['application/json']"
			dropFileType="JSON"
			parser="jsonParser"
			@submit="onImport"
			no-hint
			:importDialog.sync="importDialog">
			<template #file-detail="{ data }">
				<p class="font-weight-bold">
					{{
						Object.keys(data.setting ?? {}).filter((el) => {
							const setting = data.setting[el];
							return isTrueSetting(setting);
						}).length
					}}
					levels
				</p>
			</template>
			<template #actions1-next="{ listUpload, step, next }">
				<v-btn
					:disabled="step == 1 && !listUpload"
					text
					@click="next">
					Next
				</v-btn>
			</template>
			<template #actions1-submit>&nbsp;</template>

			<template #actions2="{ prev, submit }">
				<v-btn
					@click="prev"
					text>
					Previous
				</v-btn>
				<v-btn
					@click="submit"
					text>
					Submit
				</v-btn>
			</template>

			<template #step-2-header>Level match</template>
			<template #step-2-content="{ parserResult }">
				<v-icon v-show="!parserResult">mdi-close</v-icon>
				<v-card
					v-show="parserResult"
					class="elevation-0"
					flat>
					<v-card-text class="pa-0 mb-2">
						<div
							style="height: 11vh"
							class="mb-3"
							id="lprev">
							<div
								style="position: absolute; top: 17px; left: 28px; right: 31px">
								<LevelPreview
									v-bind="hoveredLevel"
									hideName
									ref="emptyPreview"
									previewWidth="100%"
									previewHeight="7vh"
									previewBorderWidth="3px"></LevelPreview>
							</div>
						</div>
						<v-row
							class="mx-2 align-center"
							v-for="(level, index) of getFilteredLevelList?.data?.data"
							:key="index">
							<v-col
								cols="8"
								class="py-0 my-1">
								<v-select
									outlined
									dense
									v-model="importMap[level.name]"
									hide-details
									:items="[
										'None',
										...Object.keys(parserResult?.data?.setting ?? {}).filter(
											(el) => {
												const setting = parserResult?.data?.setting[el];
												return isTrueSetting(setting);
											}
										),
									]"
									mandatory>
									<template #selection="{ item, on, attrs }">
										<v-list-item
											@mouseenter="
												(e) =>
													handleLevelHover(e, parserResult?.data?.setting[item])
											"
											@mouseleave="
												(e) =>
													handleLevelHover(e, parserResult?.data?.setting[item])
											"
											dense
											v-on="on"
											v-bind="attrs">
											{{ item !== "None" ? item : "" | Capitalize }}
										</v-list-item>
									</template>
									<template #item="{ item, on, attrs }">
										<v-list-item
											@mouseenter="
												(e) =>
													handleLevelHover(e, parserResult?.data?.setting[item])
											"
											@mouseleave="
												(e) =>
													handleLevelHover(e, parserResult?.data?.setting[item])
											"
											v-on="on"
											v-bind="attrs">
											{{ item !== "None" ? item : "" | Capitalize }}
										</v-list-item>
									</template>
								</v-select>
							</v-col>

							<v-col
								cols="1"
								class="py-0">
								<v-icon>mdi-arrow-right</v-icon>
							</v-col>
							<v-col class="py-0 text-right">
								<b class="text-button">{{ level.name }}</b>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</template>
		</ImportDialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardHeading from "../../components/shared/CardHeading.vue";
import LevelPreview from "../../components/shared/LevelPreview.vue";
import EditScheme from "../../components/jackpots/theme/EditScheme.vue";
import ImportDialog from "../../components/shared/ImportDialog.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import permissions from "../../mixins/permissions";
import { isNullish, isMobile, saveAsFile } from "../../constants/helpers";

export default {
	mixins: [permissions],
	components: {
		CardHeading,
		LevelPreview,
		EditScheme,
		ImportDialog,
	},
	data() {
		return {
			importMap: {},
			hoveredLevel: {},

			importDialog: false,
			errorBag: 0,
			themeLogo: "",
			themeWinLogo: "",
			bonusName: "",
			themeLogoSmall: "",
			themeLoaderLogo: "",
			testCounter: 0,
			wRoNg: new Set(),
			wrongAnim: new Set(),
			borderGradient: {
				type: "linear",
				degree: 180,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
			backgroundGradient: {
				type: "linear",
				degree: 180,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
			textGradient: {
				type: "linear",
				degree: 180,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
			selectedScheme: "none",
			selectedTheme: {},
			formText: {
				outerDarkColTxt: "Border Dark Color",
				outerHighlightColTxt: "Border Highlight Color",
				innerDarkColTxt: "Background Dark Color",
				innerHighlightColTxt: "Background Highlight Color",
			},
			origThemeJsonString: "",
			routeGroupID: -1,
			themeNameToEdit: "",
			groupThemeCopy: {
				none: {
					borderColors: [],
					borderColorStops: [],
					backgroundColors: [],
					backgroundColorStops: [],
					textColors: [],
					textColorStops: [],
					textStroke: "",
					animationTint: "",
					iconTint: "",
					iconUrl: "",
					visible_name: "",
				},
			},
			groupsListWithTheme: [],
			fuckingCounter: 0, //border picker force update
			fuckingCounter2: 0, // level picker force update
			fuckingCounter3: 2, //background picker force update, becouse fuck you :)
			fuckingCounter4: 4, //text picker force update
			fuckingCounter5: 4,

			rules: {
				...defaultRules,
				greaterThanContribBetMin: (v) =>
					v >= Number(this.contribBetMin) ||
					"Must be greater than Contrib Bet Min",
				betweenMinValueMaxValue: (v) =>
					(v >= Number(this.minValue) && v <= Number(this.maxValue)) ||
					"Must be between Min value and Max value",
				betweenMinBonusMaxValue: (v) =>
					(v >= Number(this.minBonus) && v <= Number(this.maxValue)) ||
					"Must be between Min Bonus and Max value",
				validateVariance: (v) =>
					(v > 0 && v <= Number(this.varianceThreshold)) ||
					"Must be between 0 and " + this.varianceThreshold,
				greaterThanMinValue: (v) =>
					v >= Number(this.minValue) || "Must be greater or equal to Min Value",
				sharesAddUp: (v) =>
					this.sharesSum == 100 || "Sum of the shares must be 100%",
			},
		};
	},

	computed: {
		isMobile,
		...mapGetters([
			"getSpecificTheme",
			"getDefaultScheme",
			"getGroupThemeSetting",
			"getFilteredThemesList",
			"getFilteredLevelList",
			"getFilteredJackpotGroupList",
			"getThemesList",
			"jackpotGroupDetail",
		]),
		cardHeight() {
			//IM SO SORRY FOR THIS
			const bagiseq = Object.values(this.errorBag ?? {});
			if (isNullish(bagiseq)) {
				return undefined;
			}
			const errorCount =
				bagiseq?.reduce((prev, next) => (next ? prev + 1 : prev), 0) ?? 0;
			const errorCount2 =
				bagiseq[0] * 1 +
				(bagiseq[1] || bagiseq[2]) * 1 +
				(bagiseq[3] || bagiseq[4]) * 1;
			const errorCount3 =
				(bagiseq[0] || bagiseq[1] || bagiseq[2]) * 1 +
				(bagiseq[3] || bagiseq[4]) * 1;

			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 5 * 75 + errorCount * 23;
				case "sm":
					return 3 * 75 + errorCount2 * 27;
				default:
					return 2 * 75 + errorCount3 * 23;
			}
		},
		routerObject() {
			if (this.routeGroupID) {
				return {
					name: "jackpot-group-detail",
					params: { jackpot_group_id: this.routeGroupID },
				};
			}
			return { name: "bonus-group-themes", params: {} };
		},

		levelList() {
			if (this.getFilteredLevelList.data?.data) {
				return this.getFilteredLevelList.data?.data;
			}
			return [];
		},

		getScheme() {
			if (this.groupThemeCopy?.setting?.[this.selectedScheme]) {
				return this.groupThemeCopy.setting[this.selectedScheme];
			}
			return undefined;
		},
	},

	mounted() {
		this.getWidth();
		window.addEventListener("resize", this.getWidth);
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.getWidth);
	},

	methods: {
		getWidth() {
			this.parentWidth = this.$refs.emptyPreview?.offsetWidth;
			this.parentWidth = this.$refs.emptyPreview?.offsetHeight;
		},
		handleLevelHover(e, item) {
			if (e.type === "mouseenter") {
				this.hoveredLevel = item ?? {};
			} else {
				if (
					this.hoveredLevel?.visible_name === item?.visible_name ||
					this.hoveredLevel?.image_name === item?.image_name
				) {
					this.hoveredLevel = {};
				}
			}
		},
		...mapActions([
			"setBreadCrumbs",
			"createTheme",
			"loadGroupThemeSetting",
			"loadFilteredThemesList",
			"loadFilteredLevelList",
			"loadFilteredJackpotGroupList",
			"loadSpecificTheme",
			"setGroupTheme",
			"loadThemesList",
			"loadJackpotGroupDetail",
		]),
		stepArtifact(s, data) {
			if (s === 2) {
				for (const level of this.getFilteredLevelList.data.data) {
					this.importMap[level.name] = Object.keys(data.setting)
						.filter((key) => this.isTrueSetting(data.setting[key]))
						.includes(level.name)
						? level.name
						: "None";
				}
			}
		},
		isTrueSetting(s) {
			return s && s.borderColors && s.borderColors.length > 0;
		},
		jsonValidator(data) {
			if (!data || !data.data) return "No data found";
			const setting = data.data.setting;
			if (!setting)
				return "You're missing the 'setting' field - don't forget it!";
			if (typeof data.data.setting !== "object")
				return "Oops, the 'setting' field has an invalid type";
			if (Object.keys(data.data.setting).length === 0)
				return "Make sure the imported schema has at least one level";
			return true;
		},
		onImport({ data }) {
			this.themeLoaderLogo = data.themeLoaderLogo;
			this.themeLogo = data.themeLogo;
			this.themeLogoSmall = data.themeLogoSmall;
			this.themeWinLogo = data.themeWinLogo;
			this.bonusName = data.bonusName;

			Object.entries(this.importMap).forEach(([key, value]) => {
				this.groupThemeCopy.setting[key] =
					value != "None"
						? data.setting[value]
						: JSON.parse(JSON.stringify(this.getDefaultScheme.veryBlankScheme));
			});
			this.forcePreviewUpdate();
			this.testCounter = (this.testCounter + 1) % 2;
			this.importMap = {};
		},
		saveAsFile,
		exportJson(e) {
			const temp = {
				setting: this.groupThemeCopy.setting,
				themeLoaderLogo: this.themeLoaderLogo,
				themeLogo: this.themeLogo,
				themeLogoSmall: this.themeLogoSmall,
				themeWinLogo: this.themeWinLogo,
				bonusName: this.bonusName,
			};
			this.saveAsFile(temp, this.groupThemeCopy.name);
		},
		thisChanged() {
			if (!this.$refs?.formiq?.errorBag) {
				this.errorBag = undefined;
				return;
			}
			this.errorBag = this.$refs?.formiq?.errorBag;
		},
		async handleRestore(lvl) {
			this.wRoNg.delete(lvl);
			this.testCounter = ++this.testCounter;
			this.groupThemeCopy.setting[lvl] = JSON.parse(
				JSON.stringify(
					this.selectedTheme.setting[lvl] ??
						this.getDefaultScheme.veryBlankScheme
				)
			);
			this.selectedScheme = "";
			await this.$nextTick();
			this.selectedScheme = lvl;

			this.forcePreviewUpdate();
		},

		handleUnset(lvl) {
			this.wRoNg.delete(lvl);
			this.testCounter = ++this.testCounter;
			this.groupThemeCopy.setting[lvl] = {};
			this.groupThemeCopy.setting[lvl] = JSON.parse(
				JSON.stringify(this.getDefaultScheme.veryBlankScheme)
			);
			this.forcePreviewUpdate();
		},

		forcePreviewUpdate() {
			this.fuckingCounter2 = (++this.fuckingCounter2 % 2) + 6;
		},

		rgbToHex(c) {
			var hex = c.toString(16);
			return hex.length == 1 ? "0" + hex : hex;
		},

		hexToRgb(hex) {
			return hex
				.replace(
					/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
					(m, r, g, b) => "#" + r + r + g + g + b + b
				)
				.substring(1)
				.match(/.{2}/g)
				.map((x) => parseInt(x, 16));
		},

		convertColors(colors, stops) {
			let tmpColorArr = [];
			const arrayLength = colors.length;
			for (let i = 0; i < arrayLength; i++) {
				let col = colors[i];
				let rgbV = this.hexToRgb(col);
				tmpColorArr.push({
					left: stops[i],
					red: rgbV[0],
					green: rgbV[1],
					blue: rgbV[2],
					alpha: 1,
				});
			}
			return tmpColorArr;
		},

		updateBorder(attr) {
			[
				this.groupThemeCopy.setting[this.selectedScheme]["borderColors"],
				this.groupThemeCopy.setting[this.selectedScheme]["borderColorStops"],
			] = attr;
			this.forcePreviewUpdate();
		},

		updateBackground(attr) {
			[
				this.groupThemeCopy.setting[this.selectedScheme]["backgroundColors"],
				this.groupThemeCopy.setting[this.selectedScheme][
					"backgroundColorStops"
				],
			] = attr;
			this.forcePreviewUpdate();
		},

		updateText(attr) {
			[
				this.groupThemeCopy.setting[this.selectedScheme]["textColors"],
				this.groupThemeCopy.setting[this.selectedScheme]["textColorStops"],
			] = attr;
			this.forcePreviewUpdate();
		},

		updateIconTint(c) {
			this.groupThemeCopy.setting[this.selectedScheme]["iconTint"] = c.hex;
			this.forcePreviewUpdate();
		},

		updateAnimationTint(c) {
			this.groupThemeCopy.setting[this.selectedScheme]["animationTint"] = c.hex;
			this.forcePreviewUpdate();
		},

		updateTextStroke(c) {
			this.groupThemeCopy.setting[this.selectedScheme]["textStroke"] = c.hex;
			this.forcePreviewUpdate();
		},

		updateVisibleName(v) {
			this.groupThemeCopy.setting[this.selectedScheme]["visible_name"] = v;
			this.forcePreviewUpdate();
		},

		updateIconUrl(v) {
			this.groupThemeCopy.setting[this.selectedScheme]["iconUrl"] = v;
			this.forcePreviewUpdate();
		},

		async submit() {
			if (
				this.groupThemeCopy != undefined &&
				this.groupThemeCopy.setting != undefined &&
				this.themeNameToEdit != undefined &&
				this.selectedTheme.setting != undefined
			) {
				!Object.keys(this.groupThemeCopy.setting).forEach((key) => {
					if (
						!this.groupThemeCopy.setting[key].iconUrl ||
						!this.groupThemeCopy.setting[key].visible_name
					) {
						if (this.groupThemeCopy.setting[key]?.borderColors?.length > 0)
							this.wRoNg.add(key);
					} else {
						this.wRoNg.delete(key);
					}
				});

				if (this.wRoNg.size > 0) {
					this.forcePreviewUpdate();
					[this.selectedScheme] = this.wRoNg;
					this.wRoNg.forEach((el) => this.wrongAnim.add(el));
					setTimeout(() => this.wrongAnim.clear(), 500);
					return;
				}

				await this.createTheme({
					params: {
						...this.groupThemeCopy.setting,
						themeLoaderLogo: this.themeLoaderLogo,
						themeLogo: this.themeLogo,
						themeLogoSmall: this.themeLogoSmall,
						themeWinLogo: this.themeWinLogo,
						bonusName: this.bonusName,
					},
					themeName: this.themeNameToEdit,
				}).then(() => {});

				if (
					this.$route.params.jackpot_group_id &&
					this.getGroupThemeSetting.data?.theme_name != this.selectedTheme?.name
				) {
					this.setGroupTheme({
						group_id: this.$route.params.jackpot_group_id,
						themeName: this.selectedTheme.name,
					});
					console.info("new theme was set for this group");
				} else {
					console.warn("no theme change in group setting");
				}

				this.$router.push(this.routerObject);
			} else {
				console.error(
					"THEME NAME OR SELECTED THEME ARE UNDEFINED, NO SUBMIT WAS MADE"
				);
			}
		},

		getThemeSetting(tmpTheme) {
			if (!tmpTheme) {
				this.groupThemeCopy = undefined;
			}

			this.groupThemeCopy = {
				id: tmpTheme?.id ?? -1,
				name: tmpTheme?.name ?? this.jackpotGroupDetail.data.data.name,
				setting: {},
			};
			this.themeLoaderLogo = tmpTheme.setting.themeLoaderLogo ?? "";
			this.themeLogo = tmpTheme.setting.themeLogo ?? "";
			this.themeLogoSmall = tmpTheme.setting.themeLogoSmall ?? "";
			this.themeWinLogo = tmpTheme.setting.themeWinLogo ?? "";
			this.bonusName = tmpTheme.setting.bonusName ?? "";

			for (let tmpLevelName of this.levelList) {
				if (
					tmpTheme.setting[tmpLevelName.name] &&
					!tmpTheme.setting[tmpLevelName.name].borderColorStops
				) {
					this.groupThemeCopy.setting[tmpLevelName.name] = {};
					this.groupThemeCopy.setting[tmpLevelName.name].visible_name =
						tmpTheme.setting?.[tmpLevelName.name].visible_name;
					this.groupThemeCopy.setting[tmpLevelName.name].animationTint =
						tmpTheme.setting?.[tmpLevelName.name].animationTint
							? tmpTheme.setting?.[tmpLevelName.name].animationTint.substring(
									0,
									7
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  )
							: tmpTheme.setting?.[tmpLevelName.name].outerDarkCol;
					this.groupThemeCopy.setting[tmpLevelName.name].iconTint =
						typeof tmpTheme.setting?.[tmpLevelName.name].iconTint == "string"
							? tmpTheme.setting?.[tmpLevelName.name].iconTint.substring(0, 7)
							: tmpTheme.setting?.[tmpLevelName.name].outerDarkCol;
					this.groupThemeCopy.setting[tmpLevelName.name].iconUrl =
						tmpTheme.setting?.[tmpLevelName.name].iconUrl;
					this.groupThemeCopy.setting[tmpLevelName.name].borderColors = [
						tmpTheme.setting?.[tmpLevelName.name].outerDarkCol.toUpperCase(),
						tmpTheme.setting?.[
							tmpLevelName.name
						].outerHighlightCol.toUpperCase(),
						tmpTheme.setting?.[tmpLevelName.name].outerDarkCol.toUpperCase(),
					];
					this.groupThemeCopy.setting[tmpLevelName.name].borderColorStops = [
						0, 50, 100,
					];
					this.groupThemeCopy.setting[tmpLevelName.name].backgroundColors = [
						tmpTheme.setting?.[tmpLevelName.name].innerDarkCol.toUpperCase(),
						tmpTheme.setting?.[
							tmpLevelName.name
						].innerHighlightCol.toUpperCase(),
						tmpTheme.setting?.[tmpLevelName.name].innerDarkCol.toUpperCase(),
					];
					this.groupThemeCopy.setting[tmpLevelName.name].backgroundColorStops =
						[0, 50, 100];
					this.groupThemeCopy.setting[tmpLevelName.name].textColors = [
						tmpTheme.setting?.[tmpLevelName.name].outerDarkCol.toUpperCase(),
						tmpTheme.setting?.[
							tmpLevelName.name
						].outerHighlightCol.toUpperCase(),
						tmpTheme.setting?.[tmpLevelName.name].outerDarkCol.toUpperCase(),
					];
					this.groupThemeCopy.setting[tmpLevelName.name].textColorStops = [
						0, 50, 100,
					];
					this.groupThemeCopy.setting[tmpLevelName.name].textStroke = tmpTheme
						.setting?.[tmpLevelName.name].textStroke
						? tmpTheme.setting?.[tmpLevelName.name].textStroke.substring(0, 7)
						: tmpTheme.setting?.[tmpLevelName.name].outerDarkCol;
				} else if (
					tmpTheme.setting[tmpLevelName.name] &&
					tmpTheme.setting[tmpLevelName.name].borderColorStops.length > 0
				) {
					this.groupThemeCopy.setting[tmpLevelName.name] = JSON.parse(
						JSON.stringify(tmpTheme.setting[tmpLevelName.name])
					);
					this.groupThemeCopy.setting[tmpLevelName.name].animationTint =
						this.groupThemeCopy.setting[
							tmpLevelName.name
						].animationTint.substring(0, 7) ||
						tmpTheme.setting?.[tmpLevelName.name].borderColors[0];
					this.groupThemeCopy.setting[tmpLevelName.name].iconTint =
						typeof tmpTheme.setting?.[tmpLevelName.name].iconTint == "string"
							? this.groupThemeCopy.setting[
									tmpLevelName.name
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  ].iconTint.substring(0, 7)
							: tmpTheme.setting?.[tmpLevelName.name].borderColors[0];
					this.groupThemeCopy.setting[tmpLevelName.name].textStroke =
						typeof tmpTheme.setting?.[tmpLevelName.name].textStroke == "string"
							? tmpTheme.setting?.[tmpLevelName.name].textStroke.substring(0, 7)
							: tmpTheme.setting?.[tmpLevelName.name].borderColors[0];
				} else {
					this.groupThemeCopy.setting[tmpLevelName.name] = {
						borderColors: [],
						borderColorStops: [],
						backgroundColors: [],
						backgroundColorStops: [],
						textColors: [],
						textColorStops: [],
						textStroke: "",
						animationTint: "",
						iconTint: "",
						iconUrl: "",
						visible_name: "",
					};
				}
			}
			this.forcePreviewUpdate();
		},

		async getGroupsWithTheme(themeName) {
			this.groupsListWithTheme = [];
			if (themeName) {
				await this.loadFilteredJackpotGroupList({
					bodyPayload: { theme_name: { eq: themeName } },
					queryParams: { per_page: 100 },
				});
				this.getFilteredJackpotGroupList.data.forEach((element) => {
					this.groupsListWithTheme.push({ id: element.id, name: element.name });
				});
			}
		},

		compareThemes(themeCopy, themeOrig) {
			for (let key in themeCopy) {
				if (
					typeof themeOrig[key] != "number" &&
					!Object.keys(themeOrig).includes(key)
				) {
					console.warn(
						" key is missing in original theme ",
						key,
						"\n original theme ",
						themeOrig[key],
						"\n copy theme",
						themeCopy[key]
					);
					return false;
				}

				if (typeof themeCopy[key] == "object") {
					if (!this.compareThemes(themeCopy[key], themeOrig[key])) {
						return false;
					}
				} else if (
					typeof themeOrig[key] == "string" &&
					themeOrig[key][0] == "#" &&
					themeOrig[key].toUpperCase() !== themeCopy[key].toUpperCase()
				) {
					console.warn(
						"it failed on > # ",
						themeOrig[key].toUpperCase(),
						" <> ",
						themeCopy[key].toUpperCase(),
						" key",
						key
					);
					return false;
				} else if (themeOrig[key] !== themeCopy[key]) {
					console.warn(
						"it failed on > values ",
						themeOrig[key].toUpperCase(),
						" <> ",
						themeCopy[key].toUpperCase(),
						" key",
						key
					);
					return false;
				}
			}
			return true;
		},

		equalObjects(o1, o2) {
			if (Object.keys(o1).length != Object.keys(o2).length) {
				return false;
			}

			for (let key in o1) {
				if (!Object.keys(o2).includes(key)) {
					return false;
				}

				if (typeof o1[key] !== typeof o2[key]) {
					return false;
				}
				switch (typeof o1[key]) {
					case "object": {
						if (!this.equalObjects(o1[key], o2[key])) {
							return false;
						}
						break;
					}
					default: {
						if (o1[key] !== o2[key]) {
							return false;
						}
					}
				}
			}
			return true;
		},
	},

	watch: {
		selectedScheme(newVal, oldVal) {
			this.fuckingCounter5 += 1;
			if (
				this.groupThemeCopy.setting &&
				this.groupThemeCopy.setting[oldVal] &&
				this.groupThemeCopy.setting[oldVal].iconUrl &&
				this.groupThemeCopy.setting[oldVal].visible_name
			) {
				this.wRoNg.delete(oldVal);
			} else {
				if (this.groupThemeCopy?.setting?.[oldVal]?.borderColors?.length > 0) {
					this.wRoNg.add(oldVal);
					this.wrongAnim.add(oldVal);
					setTimeout(() => this.wrongAnim.clear(), 500);
				}
			}
		},
		selectedTheme(newVal, oldVal) {
			this.themeNameToEdit = newVal?.name;
			if (newVal) {
				this.getThemeSetting(newVal);
			}
			this.getGroupsWithTheme(this.themeNameToEdit);
		},
	},

	async created() {
		let tmpPageName = ""; //this.$route.params.jackpot_group_name ||  this.$route.params.theme_name
		const tmpPageID =
			this.$route.params.jackpot_group_id || this.$route.params.theme_id;

		if (this.$route.params.jackpot_group_id) {
			await this.loadJackpotGroupDetail(this.$route.params.jackpot_group_id);
			tmpPageName = this.jackpotGroupDetail.data.data.name; //this.$route.params.jackpot_group_name
		} else {
			tmpPageName = this.$route.params.theme_name;
		}

		this.routeGroupID = this.$route.params.jackpot_group_id;

		let payload = {
			deleted: {
				eq: null,
			},
		};
		await this.loadFilteredLevelList({ bodyPayload: payload });

		this.themeNameToEdit =
			this.$route.params.group_theme_name || this.$route.params.theme_name;

		await this.loadFilteredThemesList({
			bodyPayload: { deleted: { eq: null } },
			queryParams: { per_page: 100 },
		});

		this.selectedTheme = this.getFilteredThemesList.data.data.find(
			(tmpTheme) => tmpTheme.name == this.themeNameToEdit
		);

		if (this.themeNameToEdit != "") {
			if (this.$route.params.jackpot_group_id) {
				await this.loadGroupThemeSetting({
					group_id: this.$route.params.jackpot_group_id,
					themeName: this.themeNameToEdit,
				}); //this.$route.params.jackpot_group_name || this.$route.params.group_theme_name })
			}

			await this.loadSpecificTheme(this.themeNameToEdit); //this.$route.params.group_theme_name })
		}

		this.setBreadCrumbs({
			pageName: tmpPageName,
			pageId: tmpPageID,
			subPageName: this.themeNameToEdit,
		});
	},
};
</script>

<!-- <style src="vue-color-gradient-picker/dist/index.css" lang="css" /> -->
<style>
.cardHeader {
	background-color: var(--v-background-base);
	color: var(--v-primary-base) !important;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	opacity: 0.8;
	position: absolute;
	font-weight: bold;
	left: 0;
	text-transform: uppercase;
	letter-spacing: -0.3em;
	transform: translate(0, 5%);
	text-orientation: upright !important;
	writing-mode: vertical-rl;
}

.cardExpand {
	transition: height 0.2s linear;
}

.errorMessage {
	position: absolute;
	bottom: 0;
}

.ui-color-picker .gradient-controls {
	display: none;
}
.ui-color-picker .color-preview-area .input-group > *:last-child {
	display: none;
}
.ui-color-picker .picker-area .preview .color-hue-alpha .alpha {
	display: none;
}
.ui-color-picker .picker-area .preview .color-hue-alpha .hue {
	margin-top: 11px;
}
.ui-color-picker .picker-area .preview .color-hue-alpha .hue .hue-area {
	height: 14px;
}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
}

.stickyButtons {
	background-color: var(--v-defaultbg-base) !important;
	position: sticky;
	z-index: 1;
	bottom: 20px;
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
